<template>
  <b-card
    no-body
  >
    <b-card-body class="m-0 p-0">
      <b-row>
        <b-col>
          <b-card
            class="mb-1"
          >
            <b-row>
              <!-- <b-col lg="3">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="tableData.search"
                    placeholder="Pencarian (tekan enter)"
                    debounce="500"
                    size="sm"
                    autocomplete="off"
                    @keyup.prevent="search()"
                  />
                </b-input-group>
              </b-col> -->
              <!-- <b-col lg="2">
            <b-form-select
              v-model="data.group"
              :options="groupOptions"
              size="sm"
              @change="getGroup()"
            >
              <template #first>
                <b-form-select-option :value="null">
                  -- Pilih Grup --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-col> -->
              <b-col lg="2">
                <b-form-select
                  :options="parameterOptions"
                  size="sm"
                  @change="getParameter()"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      -- Semua Marketplace --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col lg="2">
                <b-form-select
                  :options="stockOptions"
                  size="sm"
                  @change="getParameter()"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      -- Semua Stock --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col
                lg="3"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="tableData.rangeDate"
                    class="form-control form-control-sm"
                    :config="{ mode: 'range', dateFormat: 'Y-m-d', onClose: (_, dateStr) => { setDate(dateStr)}}"
                    placeholder="pencarian range tanggal"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="clearDate()"
                    >
                      <feather-icon icon="XCircleIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <!-- <b-col>
                <b-button
                  variant="primary"
                  size="sm"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-col> -->
              <b-col
                lg="4"
                class="text-right"
              >
                <b-button
                  variant="success"
                  size="sm"
                >
                  <feather-icon icon="DownloadIcon" />
                  Donwload Excel
                </b-button>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card
            no-body
            style="zoom: 85%"
          >
            <b-card-body class="m-0 p-0">
              <b-table
                id="report-order-table"
                :key="tableData.page"
                :fields="tableFields"
                :items="tableData.data"
                striped
                responsive="lg"
                small
                :busy="isBusy"
              >

                <!-- no data template -->
                <template
                  v-if="tableData.data.length === 0"
                  #top-row
                >
                  <td
                    v-if="tableData.search"

                    colspan="9"
                    class="text-center"
                  >
                    <b-img
                      src="@/assets/images/illustration/data_not_found.png"
                      fluid
                      width="350"
                    />

                    <p class="my-2 text-muted">
                      Kami tidak menemukan apapun, mungkin jodoh anda bukan disini
                    </p>
                  </td>
                  <td
                    v-else
                    colspan="9"
                    class="text-center"
                  >
                    <b-img
                      src="@/assets/images/illustration/task_completed.png"
                      fluid
                      width="250"
                    />
                    <p class="my-2 text-muted">
                      Semua sudah selesai! Tidak ada yang perlu dilakukan
                    </p>
                  </td>
                </template>

                <!-- numbering template -->
                <template #cell(index)="data">
                  {{ (tableData.page === 1 ? data.index + 1 : data.index + (tableData.page * tableData.perPage) + 1 - 10) }}
                </template>

                <!-- total items -->
                <template #cell(total)="data">
                  {{ data.item.order_items.length }}
                </template>

                <!-- product name template -->
                <template #cell(sync)="data">
                  <b-media>

                    <b-img
                      :src="require('@/assets/images/marketplace/tokopedia.png')"
                      class="img-fluid mb-1 rounded-circle marketplace bg-success"
                      :class="{'icon-marketplace': !data.item.has_tokopedia}"
                      width="20"
                      height="20"
                    />
                    <b-img
                      :src="require('@/assets/images/marketplace/shopee.png')"
                      class="img-fluid mb-1 rounded-circle marketplace bg-orange"
                      width="20"
                      height="20"
                      :class="{'icon-marketplace': !data.item.has_shopee}"
                    />
                    <b-img
                      :src="require('@/assets/images/marketplace/lazada-1.png')"
                      class="img-fluid mb-1 rounded-circle marketplace bg-primary"
                      width="20"
                      height="20"
                      :class="{'icon-marketplace': !data.item.has_lazada}"
                    />
                  </b-media>
                </template>

                <!-- get order_detail link -->
                <!-- <template #cell(order_id)="data">
                  <b-link
                    :to="{ name: 'orderDetails', params: { id: data.item.id } }"
                  >
                    {{ data.item.order_id }}
                  </b-link>
                </template> -->

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle" />
                    <strong> Memuat Data...</strong>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="tableData.page"
                :total-rows="tableData.totalRows"
                :per-page="tableData.perPage"
                align="right"
                size="md"
                class="mt-2 mr-2"
                aria-controls="report-order-table"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BTabs,
  BRow, BCol,
  BCardBody, BTab,
  BNav,
  BNavItem,
  BImg,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BLink,
  BMedia,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import moment from 'moment'

import flatPickr from 'vue-flatpickr-component'

import {
  defineComponent, reactive, watch, onMounted, computed, ref,
} from '@vue/composition-api'

import store from '@/store'

// import Swal from 'sweetalert2'

// tab component
import _ from 'lodash'

export default defineComponent({
  name: 'Products',
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCardBody,
    BNav,
    BNavItem,
    BImg,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
    BLink,
    BMedia,
    BPagination,
    BSpinner,
    ToastificationContent,
  },

  setup(props, { root }) {
    // const shipmentModal = defineAsyncComponent(() => import('@/views/orders/components/OrdersShopeeShipment.vue'))
    // const cancelModal = defineAsyncComponent(() => import('@/views/orders/components/OrdersShopeeCancel.vue'))
    // const asyncModal = defineAsyncComponent(() => import('@/views/orders/components/OrdersRefusedModal.vue'))
    const router = root.$router
    const rupiahFormat = value => {
      const rupiah = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      })
      return rupiah.format(value)
    }

    const isBusy = ref(false)

    const tableFields = reactive([
      {
        key: 'index',
        label: 'No',
        class: 'text-center',
      },
      {
        key: 'name',
        label: 'Nama Produk',
        thStyle: 'width: 40%',
        thClass: 'text-center',
      },
      {
        key: 'sku',
        label: 'SKU',
      },
      {
        key: 'sell_price',
        label: 'Harga Jual',
        thClass: 'text-center',
        class: 'text-right',
        thStyle: 'width: 10',
      },
      {
        key: 'modal_price',
        label: 'Harga Modal',
        thClass: 'text-center',
        class: 'text-right',
        thStyle: 'width: 10%',
      },
      {
        key: 'stock',
        label: 'Stok',
        class: 'text-center',
        thStyle: 'width: 10%',
      },
      {
        key: 'min_stock',
        label: 'Stok Minimal',
        class: 'text-center',
      },
      {
        key: 'sync',
        label: 'SYNC',
        thClass: 'text-center',
        class: 'text-right',
        thStyle: 'width: 10%',
      },
      {
        key: 'created_at',
        label: 'Tanggal Input',
        class: 'text-center',
        thStyle: 'width: 15%',
      },
      // {
      //   key: 'action',
      //   label: '',
      //   class: 'text-right',
      //   thStyle: 'width: 18%',
      // },
    ])

    const tableData = reactive({
      page: 1,
      perPage: 10,
      search: '',
      parameter: null,
      rangeDate: null,
      totalRows: computed(() => store.state.master.all.total),
      data: computed(() => {
        isBusy.value = false
        // return store.state.products.all.data.map(item => {
        //   item.sell_price = rupiahFormat(item.sell_price)
        //   if (!item.modal_price) {
        //     item.modal_price = rupiahFormat(0)
        //   } else {
        //     item.modal_price = rupiahFormat(item.modal_price)
        //   }
        //   item.created_at = moment(item.created_at).format('DD MMM YYYY')
        //   return item
        // })
        return []
      }),
    })

    const getData = async () => {
      // fecth data from store
      // isBusy.value = true
      // const payload = {
      //   page: tableData.page,
      //   perPage: tableData.perPage,
      //   search: tableData.search,
      //   parameter: tableData.parameter,
      //   rangeDate: tableData.rangeDate,
      // }
      store.dispatch('product/fetchProducts', { page: tableData.page, marketplace })
    }

    const clearDate = () => {
      tableData.rangeDate = null
    }

    const search = () => {
      tableData.page = 1
      getData()
    }

    const setDate = selectedDates => {
      const [startDate, endDate] = selectedDates.split(' to ')
      // root.$store.dispatch('setDateRange', [startDate, endDate])
    }

    const parameterOptions = reactive([
      {
        value: 'tokopedia',
        text: 'Tokopedia',
      },
      {
        value: 'shopee',
        text: 'Shopee',
      },
      {
        value: 'lazada',
        text: 'Lazada',
      },
    ])

    const stockOptions = reactive([
      {
        value: '0',
        text: 'Stok Paling Sedikit',
      },
      {
        value: '1',
        text: 'Stok Paling Banyak',
      },
    ])

    onMounted(() => {
      getData()
    })

    watch(() => tableData.page, () => {
      getData()
    })

    return {
      tableFields,
      tableData,
      isBusy,
      search,
      clearDate,
      setDate,
      parameterOptions,
      stockOptions,
      // printLabel,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
  .icon-marketplace {
    filter: grayscale(100%);
  }

  .marketplace {
    margin-right: 6px;
  }

  .bg-orange {
    background-color: #ff9100;
  }
</style>
